import React from 'react';
import ReactPlayer from 'react-player';
import siteConstant from 'src/helpers/constant/siteConstant';

export const getFileType = (src) => {
    const extension = src.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'webp', 'svg', 'ico', 'heif', 'heic', 'raw', 'nef', 'cr2', 'orf', 'arw', 'dng'];
    const videoExtensions = ['mp4', 'mkv', 'mov', 'avi', 'flv', 'wmv', 'webm', 'm4v', 'mpg', 'mpeg', '3gp', '3g2', 'mts', 'm2ts', 'ts', 'ogv', 'rm', 'rmvb'];

    if (imageExtensions.includes(extension)) {
        return 'image';
    } else if (videoExtensions.includes(extension)) {
        return 'video';
    } else {
        return 'unknown';
    }
};

export const renderThumbnail = (url, playerRef) => {
    const fileType = getFileType(url);
    if (fileType === "video") {
        return (
            <div className='video-player' style={{ width: 63, height: 63 }}>
                <ReactPlayer
                    url={url}
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    playing={false}
                    controls={false}
                    style={{
                        borderWidth: '2px',
                        borderColor: 'red',
                        padding: '5px',
                        borderRadius: '20px',
                        height: '56px',
                        width: '56px',
                        objectFit: 'cover',
                    }}
                />
            </div>
        );
    }
    return (
        <img
            src={url || siteConstant.SOCIAL_ICONS.DUMMY_PROFILE}
            className="border-2 border-red-500 p-[5px] rounded-[20px] lg:rounded-[25px] object-cover h-14 w-14 sm:h-14 sm:w-14 lg:h-16 lg:w-16"
            alt="Product"
        />
    );
};