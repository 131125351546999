import React from "react";
import { Navigate } from "react-router-dom";
import Sidebar from "./layouts/sidebar";
import AuthLayout from "./layouts/auth_layout";

// USER
const Home = React.lazy(() => import("./views/pages/dashboard/home"));
const PostFeed = React.lazy(() => import("./views/pages/feed"));
const ScheduledPost = React.lazy(() => import("./views/pages/scheduledpost/scheduledpost"));
const Dashboard = React.lazy(() => import("./views/admin/pages/dashboard/index"));
const PostDetails = React.lazy(() => import("./views/admin/pages/postDetails"));
const UserManagement = React.lazy(() => import("./views/admin/pages/user-management"));
const Profile = React.lazy(() => import("./views/pages/profile/profile"));
const SignIn = React.lazy(() => import("./views/pages/auth/sign-in"));
const Forgot = React.lazy(() => import("./views/pages/auth/forgot-password"));
const Reset = React.lazy(() => import("./views/pages/auth/reset-password"));
const OtpVerification = React.lazy(() => import("./views/pages/auth/otp-verify"));
const DataNotFound = React.lazy(() => import("src/views/components/custom/DataNotFound"));
const InstaLogin = React.lazy(() => import("./views/pages/auth/instagram/login"));
const InstaResponse = React.lazy(() => import("./views/pages/auth/instagram/response"));
const NotFoundPage = React.lazy(() => import("./views/pages/exception"));

const instaAuthRoutes = {
  path: '/oauth/instagram',
  children: [
    { path: "/oauth/instagram", element: <InstaLogin /> },
    { path: "/oauth/instagram/response", element: <InstaResponse /> },
  ]
}

const publicOther = {
  path: "*", element: <NotFoundPage />
}
// USER ROUTES
const userRoutes = [
  {
    path: "/",
    element: <Sidebar />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" /> },
      { path: "/dashboard", element: <Home /> },
      { path: "/scheduled-post", element: <ScheduledPost /> },
      { path: "/feed", element: <PostFeed /> },
      { path: "/profile", element: <Profile /> },
      { path: "/404", element: <DataNotFound /> },
    ],
  },
  {
    ...instaAuthRoutes
  },
  { ...publicOther }
];

const adminRoutes = [
  {
    path: "/admin",
    element: <Sidebar />,
    children: [
      { path: "/admin", element: <Navigate to="/admin/dashboard" /> },
      { path: "/admin/dashboard", element: <Dashboard /> },
      { path: "/admin/post-details", element: <PostDetails /> },
      { path: "/admin/user-management", element: <UserManagement /> },
      { path: "/admin/404", element: <DataNotFound /> },

    ],
  },
  {
    ...instaAuthRoutes
  },
  { ...publicOther }
];


// PUBLIC ROUTES
const publicRoute = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      { path: "/", element: <Navigate to="/sign-in" /> },
      { path: "/sign-in", element: <SignIn /> },
      { path: "/forgot-password", element: <Forgot /> },
      { path: "/otp-verify", element: <OtpVerification /> },
      { path: "/reset-password", element: <Reset /> },
      { path: "/*", element: <NotFoundPage /> },
    ],
  },
  {
    ...instaAuthRoutes
  }
];

export { publicRoute, userRoutes, adminRoutes }