import Instagram from "src/assets/images/svg_icon/instagram-icon.svg";
import Linkedin from "src/assets/images/svg_icon/linkdin-icon.svg";
import Pinterest from "src/assets/images/svg_icon/pintrest-icon.svg";
import Facebook from "src/assets/images/svg_icon/facebook-icon.svg";
import TikTok from "src/assets/images/svg_icon/tiktok-icon.svg";
import Twitter from "src/assets/images/svg_icon/twitter-icon.svg";
import Vimeo from "src/assets/images/svg_icon/vimeo-icon.svg";
import YouTube from "src/assets/images/svg_icon/youtube-icon.svg";
import Audience from "src/assets/images/svg_icon/audience-icon.svg";
import Calender from "src/assets/images/svg_icon/calender-icon.svg";
import Location from "src/assets/images/svg_icon/location-icon.svg";
import Person from "src/assets/images/svg_icon/person-icon.svg";
import Dailymation from "src/assets/images/svg_icon/dailymotion-icon.svg";
import logo from "src/assets/images/logo.svg";
import dummyProfile from "src/assets/images/svg_icon/dummy_profile.svg";
import deletePostConfirmation from "src/assets/images/svg_icon/delete-post-confirmation.svg";
import deleteUserInfo from "src/assets/images/svg_icon/info-icon.svg";
import deleteUserBGImage from "src/assets/images/svg_icon/delete-icon-bg.svg";
import DashboardIcon from "src/assets/images/svg_icon/dashboard-icon.svg"
import AddIcon from "src/assets/images/svg_icon/add-icon.svg"
import UserIcon from "src/assets/images/svg_icon/user-icon.svg"
import LikeIcon from "src/assets/images/svg_icon/like-icon.svg"
import CommentIcon from "src/assets/images/svg_icon/comment-icon.svg"
import TimeIcon from "src/assets/images/svg_icon/Time-icon.svg"
import LcationIcon from "src/assets/images/svg_icon/location-svg.svg"
import PlayStore from "src/assets/images/play-sore-button.svg"
import AppStore from "src/assets/images/app-store.svg"





const SOCIAL_ICONS = {
    INSTAGRAM_ICON: Instagram,
    LINKEDIN_ICON: Linkedin,
    PINTEREST_ICON: Pinterest,
    FACEBOOK_ICON: Facebook,
    TIKTOK_ICON: TikTok,
    TWITTER_ICON: Twitter,
    VIMEO_ICON: Vimeo,
    YOUTUBE_ICON: YouTube,
    AUDIANCE_ICON: Audience,
    CALENDER_ICON: Calender,
    LOCATION_ICON: Location,
    PERSON_ICON: Person,
    DAILYMOTION_ICON: Dailymation,
    YOOIILOGO: logo,
    DUMMY_PROFILE: dummyProfile,
    DELETE_POST_CONFIRMATION: deletePostConfirmation,
    DELETE_USER_INFO: deleteUserInfo,
    DELETE_USER_BG: deleteUserBGImage,
    LIKE_ICON: LikeIcon,
    COMMENT_ICON: CommentIcon,
    LOCATION_ICONN: LcationIcon,
    PLAY_STORE: PlayStore,
    APP_STORE: AppStore

}
const ICONS = {
    Dashboard_Icon: DashboardIcon,
    Add_Icon: AddIcon,
    User_Icon: UserIcon,
    Time_Icon: TimeIcon
}
const CHANNEL_LIST = [
    { id: 1, name: "Instagram", icon: Instagram, iconColor: "#E1306C", selected: false, color: "bg-pink-100" },
    { id: 2, name: "Facebook", icon: Facebook, iconColor: "#3b5998", selected: false, color: "bg-blue-100" },
    { id: 3, name: "YouTube", icon: YouTube, iconColor: "#FF0000", selected: false, color: "bg-red-100" },
    { id: 4, name: "Pinterest", icon: Pinterest, iconColor: "#E60023", selected: false, color: "bg-pink-200" },
    { id: 5, name: "LinkedIn", icon: Linkedin, iconColor: "#0077b5", selected: false, color: "bg-blue-200" },
    { id: 6, name: "Twitter", icon: Twitter, iconColor: "#000000", selected: false, color: "bg-gray-200" },
    { id: 7, name: "Vimeo", icon: Vimeo, iconColor: "#1ab7ea", selected: false, color: "bg-blue-300" },
    { id: 8, name: "TikTok", icon: TikTok, iconColor: "#000000", selected: false, color: "bg-black" },
    { id: 9, name: "Dailymotion", icon: Dailymation, iconColor: "#1ab7ea", selected: false, color: "bg-blue-400" }
]

const INDENTIFIERS = {
    USERDATA: 'userdata',
    acceptlanguage: 'acceptlanguage',
    currentTab: 'currentactivetab'
}

export default { SOCIAL_ICONS, CHANNEL_LIST, INDENTIFIERS, ICONS }