import React, { useContext, useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import postimg from "src/assets/images/postimg1.svg";
import { InputAdornment, Dialog } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { FormGroup, FormControlLabel, Stack, Typography } from "@mui/material";
import Android12Switch from "src/views/components/UploadPost/socialswitch";
import moment from 'moment'
import { CustomTextField } from "src/views/components/custom/CustomTextField";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { URL as API_URL } from "src/helpers/constant/Url";
import { setApiMessage } from "src/helpers/context/toaster";
import apiInstance from "src/helpers/Axios/axiosINstance";
import { fetchFromStorage } from "src/helpers/context/storage";
import siteConstant from "src/helpers/constant/siteConstant";
import { IntlContext } from 'src/App';
import { getFileType } from "src/helpers/constant/utils";
import { ListGroup, ListGroupItem } from "flowbite-react";

// function highlightHashtagsAndMentions(text) {
//   const regex = /([#@][\w-]+)/g;
//   const parts = text.split(regex);

//   return (
//     parts?.map((part, index) => {
//       if (part.match(regex)) {
//         return (<span key={index} style={{ color: part.startsWith('#') || part.startsWith('@') ? 'blue' : 'green' }}>
//           {part}
//         </span>)
//       }
//       return part;
//     })
//   )
// }


const UploadPost = ({ open, onClose }) => {
  const fileInputRef = useRef(null);
  const [uploaded, setUploaded] = useState(false);
  const [images, setImages] = useState([]);
  const [uploadedFile, setuploadedFile] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selected, setSelected] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [shareChecked, setshareChecked] = useState(false);
  const socialMediaConnected = fetchFromStorage(siteConstant?.INDENTIFIERS?.USERDATA)
  const [startDate, setStartDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [search, setSearch] = useState("");

  const data = [
    { "Delhi": "Delhi" },
    { "Mumbai": "Mumbai" },
    { "Kolkāta": "Kolkāta" },
    { "Bangalore": "Bangalore" },
    { "Chennai": "Chennai" },
    { "city": "Hyderābād" },
    { "Pune": "Pune" },
    { "city": "Ahmedabad" },
    { "Surat": "sūrat" },
  ];

  const filteredLocations = data
  .filter((item) => {
    const location = Object.values(item)[0].toLowerCase();
    return showAll || location.includes(search.toLowerCase());
  })
  .sort((a, b) => {
    const locationA = Object.values(a)[0].toLowerCase();
    const locationB = Object.values(b)[0].toLowerCase();
    if (locationA.startsWith(search.toLowerCase())) return -1;
    if (locationB.startsWith(search.toLowerCase())) return 1;
    return 0;
  });

const handleSelect = (location) => {
  setSearch(location); // Set selected location to input
  setShowAll(false); // Hide the list after selection
};

  const handleDateChange = (date) => {
    setStartDate(date);
    setShowDatePicker(false);
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
      setUploaded(false);
    }
  };

  const handleFileInputClick = () => {
    if (!selected) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log(files);
      const uploadedImages = Array.from(files).map((file) => {
        return {
          url: URL.createObjectURL(file),
          is_video: getFileType(file?.name) == "video" ? true : false
        }
      }
      );
      setImages(uploadedImages);
      setCurrentImageIndex(0);
      setUploaded(true);
      setSelected(true);
      setuploadedFile(files);
    }
  };

  const handleNextSlide = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevSlide = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const CustomNextArrow = ({ onClick }) => (
    <div className="absolute top-1/2 right-[-23px] transform -translate-y-1/2 z-10 cursor-pointer bg-red-50 p-2 rounded-full">
      <KeyboardArrowRightIcon
        className="text-red-500 hover:text-red-700"
        onClick={onClick}
      />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div className="absolute top-1/2 left-[-19px] transform -translate-y-1/2 z-10 cursor-pointer bg-red-50 p-2 rounded-full">
      <KeyboardArrowRightIcon
        className="rotate-180 text-red-500 hover:text-red-700 "
        onClick={onClick}
      />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow onClick={handleNextSlide} />,
    prevArrow: <CustomPrevArrow onClick={handlePrevSlide} />,
  };

  const handleDialogClose = () => {
    onClose();
    setUploaded(false);
    setImages([]);
    setCurrentImageIndex(0);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    const acceptedFiles = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/') || file.type.startsWith('video/')
    );
    if (acceptedFiles.length > 0) {
      console.log(acceptedFiles);
      const uploadedImages = Array.from(acceptedFiles).map((file) => {
        return {
          url: URL.createObjectURL(file),
          is_video: getFileType(file?.name) == "video" ? true : false
        }
      }
      );
      setImages(uploadedImages);
      setCurrentImageIndex(0);
      setUploaded(true);
      setSelected(true);
      setuploadedFile(files)
    }
  };

  const [platforms, setPlatforms] = useState([]);

  useEffect(() => {
    const connectedPlatforms = Object.keys(socialMediaConnected).filter(key => socialMediaConnected[key] === true);
    const updatedPlatforms = siteConstant?.CHANNEL_LIST.filter((platform) => {
      if (connectedPlatforms.includes(platform?.name)) {
        return { ...platform };
      }
    }).map((platform) => {
      setshareChecked(true)
      return { ...platform, selected: true }
    })
    setPlatforms(updatedPlatforms);
  }, []);

  const handleShareAll = (checked) => {
    let updatedPlatforms = platforms.map((platform) => {
      return { ...platform, selected: checked };
    });
    setshareChecked(checked)

    setPlatforms(updatedPlatforms);
  };

  const handleToggle = (id) => {
    console.log(id)
    let updatedPlatforms = platforms.map((platform) =>
      platform?.id === id ? { ...platform, selected: !platform?.selected } : platform
    );
    setshareChecked(!updatedPlatforms.some((platform) => !platform?.selected))
    setPlatforms(updatedPlatforms);
  };

  return (
    <>
      <Dialog
        open={open && !uploaded}
        onClose={handleDialogClose}
        className="fixed inset-0 z-10 flex items-center justify-center"
      >
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={handleBackdropClick}
        ></div>
        <div
          className="relative bg-white rounded-3xl overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full p-8 "
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className={`border border-5 border-gray-400 border-dashed rounded-xl p-6 cursor-pointer font-Montserat ${isDragging && "border-Red border-2"
              }`}
          >
            <div
              className="bg-white px-4 pt-5 pb-2 sm:p-6 sm:pb-4"
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={handleFileInputClick}
              ref={fileInputRef}
            >
              <div className="sm:flex sm:items-center sm:justify-center">
                <div className="">
                  <p className="sm:text-3xl font-bold text-center pb-5 text-gray-900">
                    {localesData?.USER_WEB?.CREATE_NEW_POST}
                  </p>
                  <div className="mt-2 flex flex-col justify-center items-center">
                    <img src={postimg} alt="Post" className="w-24 h-24" />
                    <p className="text-sm mt-4 font-semibold">
                      {localesData?.USER_WEB?.DRAG_PHOTOS_AND_VIDEOS_HERE}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="px-4 py-3 sm:px-4 flex justify-center"
            // onClick={handleFileInputClick}
            >
              <label
                htmlFor="upload-input"
                className="bg-Red px-4 py-3 rounded-xl text-white text-sm font-semibold cursor-pointer"
              >
                {localesData?.USER_WEB?.SELECT_YOUR_PHOTOS}
              </label>
              <input
                id="upload-input"
                type="file"
                accept="image/*,video/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
            </div>
          </div>
        </div>
      </Dialog>

      {uploaded && (
        <Dialog
          open={uploaded}
          onClose={handleDialogClose}
          className="fixed inset-0 z-10 sm:flex sm:items-center sm:justify-center  "
        >
          <div
            className=" px-10 fixed inset-0  flex justify-center items-center bg-gray-900 bg-opacity-50 overflow-auto"
            aria-hidden="true"
            onClick={handleBackdropClick}
          >

            <Formik
              initialValues={{
                title:"",
                description: "",
                location: "",
                audience: false,
                schedule: moment().local(),
                facebook: false,
                instagram: false,
                linkedin: false,
                pinterest: false,
                vimeo: false,
                youtube: false,
                dailymotion: false,
                twitter: false,
                tagged_in: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true);
                try {
                  console.log(values);
                  const form = new FormData()
                  form.append('title', values?.title)
                  form.append('description', values?.description)
                  form.append('location', values?.location)
                  form.append('is_private', values?.audience)
                  platforms?.filter(platform => platform.name == 'Facebook')?.[0]?.selected && form.append('facebook', 'True');
                  platforms?.filter(platform => platform.name == 'Instagram')?.[0]?.selected && form.append('instagram', 'True');
                  platforms?.filter(platform => platform.name == 'Linkedin')?.[0]?.selected && form.append('linkedin', 'True');
                  platforms?.filter(platform => platform.name == 'Pinterest')?.[0]?.selected && form.append('pinterest', 'True');
                  platforms?.filter(platform => platform.name == 'Vimeo')?.[0]?.selected && form.append('vimeo', 'True');
                  platforms?.filter(platform => platform.name == 'YouTube')?.[0]?.selected && form.append('youtube', 'True');
                  platforms?.filter(platform => platform.name == 'Dailymotion')?.[0]?.selected && form.append('dailymotion', 'True');
                  platforms?.filter(platform => platform.name == 'Twitter')?.[0]?.selected && form.append('twitter', 'True');
                  values?.tagged_in && form.append('tagged_in', values?.tagged_in);
                  form.append('scheduled_at', values?.schedule);
                  for (let file of uploadedFile) {
                    form.append('upload_files', file)
                  }
                  const { status, data } = await apiInstance.post(API_URL.UPLOAD_POST, form)
                  if (data) {
                    if (data?.status) {
                      setApiMessage('success', data?.message)
                    } else {
                      setApiMessage('error', data?.message)
                    }
                  } else {
                    setApiMessage('error', localesData?.USER_WEB?.UPLOAD_FAILED)
                  }
                  setSubmitting(false);
                } catch (error) {
                  console.log("error", error);
                  setApiMessage('error', error?.message)
                } finally {
                  setLoading(false);
                }
                console.log("createpost", values)

                setTimeout(() => {
                  setSubmitting(false);
                  handleDialogClose();
                }, 400);
              }}
            >
              {({ errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                isSubmitting, }) => (
                <Form onSubmit={handleSubmit} className="space-y-2.5 font-Montserrat">
                  <div className={`w-xl sm:max-w-2xl ${images.length === 1 ? 'lg:max-w-3xl' : 'lg:max-w-[770px]'} w-full md:flex md:flex-col md:space-x-6 sm:mt-40 bg-white shadow-lg rounded-[30px] overflow-hidden`}>
                    <div className="relative">
                      <p className="text-center text-xl md:text-2xl font-bold text-gray-800 mb-4 bg-gray-100 py-4 border-b">
                        {localesData?.USER_WEB?.CREATE_NEW_POST}
                      </p>
                      <div className="absolute top-5 right-6 font-medium font-Montserat text-[15px]">
                        <button
                          variant="contained"
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? <div role="status ">
                            <svg
                              aria-hidden="true"
                              className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span class="sr-only">Loading...</span>
                          </div> : 'Save'}
                        </button>
                      </div>
                    </div>
                    <div className="flex flex-col h-[500px] md:flex md:flex-row items-center md:space-x-6">
                      <div className="w-[220px] justify-center text-center md:w-1/2 mb-4 md:mt-4 pe-5 relative">
                        {images.length === 1 ? (
                          images[0]?.is_video ?
                            <video
                              src={images[0]?.url}
                              controls
                              className="md:w-full w-[250px] md:h-full h-[250px] inline-block rounded-2xl"
                            >
                              {localesData?.USER_WEB?.YOUR_BRAWSER_DOES_NOT_SUPPORT_THE_VIDEO_TAG}
                            </video> : <img
                              src={images[0]?.url}
                              alt={`Image 1`}
                              className="md:w-full w-[250px] md:h-full h-[250px] inline-block rounded-[30px]"
                            />
                        ) : (
                          <Slider {...settings}>
                            {images.map((item, index) => {
                              return (
                                <div key={index} className="h-auto justify-center  text-center">
                                  {item?.is_video ? (
                                    <video
                                      src={item?.url}
                                      controls
                                      className="md:w-full w-[200px] md:h-full h-[200px] inline-block rounded-2xl"
                                    >
                                      {localesData?.USER_WEB?.YOUR_BRAWSER_DOES_NOT_SUPPORT_THE_VIDEO_TAG}
                                    </video>
                                  ) : (
                                    <img
                                      src={item?.url}
                                      alt={`Media ${index + 1}`}
                                      className="md:w-full w-[200px] md:h-full h-[200px] inline-block rounded-2xl"
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </Slider>
                        )}
                      </div>
                      <div className="w-full md:w-1/2 grid grid-cols-1 gap-4 px-5 md:px-0 md:pe-4 overflow-auto md:h-[500px]">
                        <div className="flex gap-4">
                          <div className="">
                            <img
                              alt="User Photo"
                              className='h-11 w-11 rounded-[14px] border-[2px] border-Red p-1' 
                              src={socialMediaConnected?.profile_image || siteConstant.SOCIAL_ICONS.DUMMY_PROFILE}
                            />
                          </div>
                          <div>
                            <p className="text-sm font-bold font-Montserat mt-1">
                              {socialMediaConnected?.name ?? 'Anton Demeron'}
                            </p>

                          </div>
                        </div>
                        <div className="">
                          <CustomTextField
                              variant="outlined"
                              label={localesData?.USER_WEB?.TITLE}
                              fullWidth
                              id="title"
                              name="title"
                             className=" rounded-lg border-transparent p-4 pe-12 text-sm relative  pt-0.5 shadow-sm shadow-red-200"
                              placeholder={localesData?.USER_WEB?.TITLE}
                             value={values.title}
                              onChange={handleChange}
                              onBlur={handleBlur} />
                           </div>

                        <div className="">
                          <CustomTextField
                              variant="outlined"
                              label={localesData?.USER_WEB?.WRITE_CAPTION}
                              multiline
                              fullWidth
                              id="description"
                              name="description"
                             className="w-full rounded-lg p-4 pe-12 text-sm relative  pt-0.5 shadow-sm shadow-red-200"
                              placeholder={localesData?.USER_WEB?.WRITE_CAPTION}
                             value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur} />
                           </div>
                        <div
                          className="border rounded-lg  shadow-sm shadow-red-200"
                          style={{ position: "relative" }}
                        >
                          <CustomTextField
                            variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            borderColor={"none"}
                            name={"location"}
                            placeholder="Enter location"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                              setShowAll(true); 
                            }}
                            onFocus={() => setShowAll(true)} 
                            onBlur={() =>
                              setTimeout(() => setShowAll(false), 100)
                            } 
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                   src={siteConstant?.SOCIAL_ICONS?.LOCATION_ICONN}
                                    alt="location-icon"
                                    style={{ height: "25px" }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }}
                          />

                          {showAll && (
                            <ListGroup
                              style={{
                                marginTop: "10px",
                                maxHeight: "150px",
                                overflowY: "auto",
                                position: "absolute",
                                zIndex: 1000,
                                width: "100%",
                              }}
                            >
                              {filteredLocations.map((item, index) => (
                                <ListGroupItem
                                  key={index}
                                  onMouseDown={() =>
                                    handleSelect(Object.values(item)[0])
                                  } // Use onMouseDown instead of onClick
                                  style={{ cursor: "pointer" }}
                                >
                                  {Object.values(item)[0]}
                                </ListGroupItem>
                              ))}
                            </ListGroup>
                          )}
                        </div>
                        <div className=" border rounded-lg pt-0.5 shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none text-black focus:ring-none"
                            fullWidth
                            name={'tagged_in'}
                            placeholder={localesData?.USER_WEB?.ENTER_PEOPLE_TO_TAG}
                            borderColor={'none'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.tagged_in}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.PERSON_ICON} style={{ height: '25px' }}></img>
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="relative border pt-0.5 rounded-lg shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            name="audience"
                            placeholder={localesData?.USER_WEB?.AUDIENCE}
                            value={values.audience ? 'Private' : 'Public'}
                            borderColor={'none'}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.AUDIANCE_ICON} style={{ height: '25px' }} alt={localesData?.USER_WEB?.AUDIENCE} />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Android12Switch checked={values.audience} onClick={(e) => setFieldValue('audience', e.target.checked)} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div className="relative border rounded-lg pt-1 shadow-sm shadow-red-200">
                          <CustomTextField
                            variant="outlined"
                            className="outline-none focus:ring-none"
                            fullWidth
                            name="schedule"
                            placeholder={localesData?.USER_WEB?.SCHEDULE_POST}
                            borderColor={'none'}
                            value={startDate ? startDate.toLocaleString() : startDate}
                            onClick={() => setShowDatePicker(!showDatePicker)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={siteConstant?.SOCIAL_ICONS?.CALENDER_ICON} style={{ height: '25px' }} alt="Calender" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <KeyboardArrowRightIcon className="text-arrowIconColor" />
                                </InputAdornment>
                              ),
                            }}
                          />

                        </div>
                        {showDatePicker && (
                          <div className="border-none rounded-lg shadow-lg bg-white">
                            <DatePicker
                              selected={startDate}
                              onChange={handleDateChange}
                              onBlur={() => setShowDatePicker(false)}
                              showTimeSelect
                              dateFormat="Pp"
                              inline
                            />
                          </div>
                        )}
                        {platforms.length ? (<div className="flex justify-between items-center font-Montserrat">
                          <Typography variant="subtitle1">{localesData?.USER_WEB?.SHARE_WITH}</Typography>
                          <FormGroup>
                            <FormControlLabel
                              control={<Android12Switch checked={shareChecked} onClick={(e) => handleShareAll(e.target.checked)} />}
                              label=""
                            />
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            ></Stack>
                          </FormGroup>
                        </div>) : <></>}

                        <div>
                          {platforms.map((platform) => (
                            <div
                              key={platform?.id}
                              className="flex justify-between items-center font-Montserrat mb-4 w-full"
                            >
                              <div className="flex items-center">
                                <div className="h-6 w-8 flex justify-center items-center">
                                  <img
                                    src={`${platform?.icon}`}
                                    style={{ color: platform?.iconColor, fontSize: '25px' }}
                                  />
                                </div>
                                <span className="font-semibold text-xs text-black sm:text-sm ml-5">
                                  {platform?.name}
                                </span>
                              </div>
                              <FormGroup className="flex ">
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      checked={platform?.selected}
                                      onChange={() => handleToggle(platform?.id)}
                                    />
                                  }
                                  label=""
                                />
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  alignItems="center"
                                ></Stack>
                              </FormGroup>
                            </div>
                          ))}
                        </div>


                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Dialog >
      )}
    </>
  );
};

export default UploadPost;
